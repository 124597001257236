<template>
	<div class="auth">
		<img src="@/assets/images/bj.png" width="100%" alt="">
		<div class="btn" @click="toAuth">微信授权</div>
		<van-loading size="24px" color="#fff" vertical class="loading-box" v-if="loading">绑定中，请稍后...</van-loading>
	</div>
</template>

<script>
	import {
		wechatAuth
	} from '@/http/api';
	export default {
		components: {},
		props: {},
		data() {
			return {
				loading: false,
				shopUserInfo:{},
				zappid: 'wx2072f5b290784490'
			}
		},
        
        beforeCreate() {
            if (!this.$LStorage.getItem("shopUserInfo")) {
                this.$router.replace({
                    path: "/merchatlogin"
                });
            }
        },

		mounted() {
			this.shopUserInfo = this.$LStorage.getItem("shopUserInfo"); 
			if (this.$route.query.code) {
				this.loading = true;
				this.code = this.$route.query.code;
				this.wechatAuth();
			}
		},
		methods: {
			toAuth() {
				let redirectUri = document.URL;
				window.location.href =
					'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + this.zappid + '&redirect_uri=' +
					encodeURI(redirectUri) + '&response_type=code&scope=snsapi_base&state=auth#wechat_redirect';
			},
			// 执行授权接口
			async wechatAuth() {
				const res = await wechatAuth({
					data: {
						appid: this.zappid,
						code: this.code,
						shop_user_id: this.shopUserInfo.shop_user_id
					}
				})
				this.loading = false;
				console.log(res);
				if (res.code == 200) {
					this.$dialog.alert({
						title: '提示',
						message: res.msgs
					}).then(() => {
						this.shopUserInfo.is_auth = 1;
						this.$LStorage.setItem('shopUserInfo', this.shopUserInfo)
						this.$router.replace('/merchantwork');
					});
				} else {
					this.$dialog.alert({
						title: '提示',
						message: res.msgs
					}).then(() => {
						this.$router.replace('/merchantwork');
					});
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.btn {
		width: 4.99rem;
		height: 0.92rem;
		line-height: 0.92rem;
		text-align: center;
		margin: 0.5rem auto;
		border: 1px solid #E14F2A;
		border-radius: 0.46rem;
		font-size: 0.3rem;
		color: #E14F2A;
	}

	.loading-box {
		position: fixed;
		width: 100%;
		height: 100vh;
		left: 0;
		top: 0;
		background-color: rgba($color: #000000, $alpha: 0.6);
		display: flex;
		justify-content: center;
	}
</style>
